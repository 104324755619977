@import '@angular/material/theming';
@import "@aveva/connect-web-core/aveva.themes";

html {
  font-family: "Roboto", sans-serif;
}

mat-dialog-container {
  margin: auto;
}

.unstyle-button {
  background: transparent;
  border: none;
  padding: 0;
  outline: none;
}
